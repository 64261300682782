/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useGetCompanySettingsQuery, usePostChangePasswordMutation } from '../../api/appApi';
import { FormInput } from '../../components/FormInputs';
import { MyNewPasswordData } from '../../api/modules/user/userTypes';
import useMessages from '../../hooks/useMessages';
import { translateMyPasswordToApi } from '../../api/modules/user/userTranslator';
import { InputError } from '../../components/FormInputs/InputLabel';
import Card from '../../components/Card/Card';
import Button from '../../components/Button/Button';
import { PASSWORD_REGEX } from '../../../utils/consts';

const PasswordChangeRequired = () => {
  const getMessage = useMessages();
  const { isSuccess: companySettingsFetched } = useGetCompanySettingsQuery();
  const [postChangePassword, result] = usePostChangePasswordMutation();
  const methods = useForm<MyNewPasswordData>({ mode: 'onTouched' });
  const [backendErrors, setBackendErrors] = useState([] as string[]);
  const navigate = useNavigate();

  const onSubmit = (data: MyNewPasswordData) => {
    postChangePassword(translateMyPasswordToApi(data));
  };

  useEffect(() => {
    if (companySettingsFetched) {
      navigate('/');
    }
    if (result.status === 'fulfilled') {
      setBackendErrors([]);
      methods.setValue('oldPassword', '');
      methods.setValue('newPassword', '');
      methods.setValue('confirmNewPassword', '');
      navigate('/');
    } else if (result.status === 'rejected') {
      // @ts-ignore
      const errors = result.error?.data?.errors || [];
      setBackendErrors(Array.isArray(errors) ? errors : [errors]);
    }
  }, [result, companySettingsFetched]);

  if (companySettingsFetched) return null;

  return (
    <Card className="w-full md:w-1/2 mx-auto ">
      <FormProvider {...methods}>
        <h2 className="border-b w-full pb-8 text-center text-header-sm">{getMessage('profile.user.changePassword.passwordChangeRequired')}</h2>
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          className="flex flex-col my-7 items-center"
        >
          <FormInput
            type="password"
            className="mobile:w-full w-2/3"
            inputClassName="w-full"
            id="oldPassword"
            label={getMessage('profile.user.changePassword.oldPassword')}
            options={{ required: getMessage('profile.user.changePassword.oldPassword.error.required') }}
          />
          <FormInput
            type="password"
            className="mobile:w-full w-2/3"
            inputClassName="w-full"
            id="newPassword"
            label={getMessage('profile.user.changePassword.newPassword')}
            options={{
              required: getMessage('profile.user.changePassword.newPassword.error.required'),
              pattern: {
                value: PASSWORD_REGEX,
                message: getMessage('profile.user.changePassword.error.newPassword.pattern'),
              },
            }}
          />
          <FormInput
            type="password"
            className="mt-4 mobile:w-full w-2/3"
            inputClassName="w-full"
            id="confirmNewPassword"
            label={getMessage('profile.user.changePassword.confirmNewPassword')}
            options={{
              required: getMessage('profile.user.changePassword.confirmNewPassword.error.required'),
              pattern: {
                value: PASSWORD_REGEX,
                message: getMessage('profile.user.changePassword.error.changePassword.pattern'),
              },
            }}
          />
          {backendErrors && backendErrors.map((error) => (
            <InputError key={error} message={error} />
          ))}
          <Button type="submit" className="mt-8">{getMessage('confirm')}</Button>
        </form>
      </FormProvider>
    </Card>
  );
};

export default PasswordChangeRequired;
